import React, { useMemo } from "react";
import { css } from "goober";

import { appURLs } from "@/app/constants.mjs";
import { AdPlaceholder, DisplayAd } from "@/feature-ads/AdWrapper.jsx";
import adsRefs from "@/feature-ads/refs.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const useShouldShowInContentBanner = () => {
  const { remoteConfig, showInContentBanner } = useSnapshot(adsRefs);
  const route = useRoute((a, b) => a?.currentPath === b?.currentPath, {
    isGlobal: true,
  });

  const canShowBanner = useMemo(() => {
    const matchedInContentRoute = adsRefs.IN_CONTENT_BANNER_PATHS.some((r) =>
      (route?.currentPath ?? "").includes(r),
    );
    if (!showInContentBanner || !matchedInContentRoute) return false;

    return remoteConfig.adhesion;
  }, [showInContentBanner, remoteConfig.adhesion, route?.currentPath]);

  return [canShowBanner] as const;
};

const InContentBanner = ({ center }): React.ReactNode => {
  const [shouldShowInContentBanner] = useShouldShowInContentBanner();

  if (!shouldShowInContentBanner) return null;

  const msiImage = `${appURLs.CDN}/infeed-images/msi-728x90.webp`;
  const msiLink = `${appURLs.MSI}?utm_source=blitz-app&utm_medium=ad&utm_campaign=blitz-fill-ad`;

  return (
    <div className={center && InFeedBannerContainer()}>
      <DisplayAd
        placeholder={<AdPlaceholder img={msiImage} link={msiLink} />}
        id={"display-desktop-anchor-infeed"}
        className={DisplayAdLeaderboard()}
      />
    </div>
  );
};
export default InContentBanner;

const InFeedBannerContainer = () => css`
  display: flex;
  width: var(--page-width);
  justify-content: center;
`;

const DisplayAdLeaderboard = () => css`
  --width: 728px;
  height: 90px;
  width: var(--width);
  background: transparent;
  outline: none;

  &:has(iframe) {
    background: var(--shade6);
  }
`;
