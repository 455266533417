import { css } from "goober";

import featureFlags, { allowRead } from "@/__main__/feature-flags.mjs";
import { setFeatureFlag } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import adsRefs from "@/feature-ads/refs.mjs";
import InContentBanner from "@/feature-ads-in-content-banner/InContentBanner.jsx";
import matchRefs from "@/shared/Match.refs.jsx";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const originals = mapOriginalRefs({
  adsRefs,
  matchRefs,
});

function setupInContentAds() {
  originals.set({
    adsRefs: {
      showInContentBanner: true,
    },
    matchRefs: {
      InContentBanner: InContentBanner,
      columnsContainerClassName: () => css`
        @media screen and (min-width: 1240px) {
          grid-template-columns: repeat(12, 1fr);
          .span-1 {
            grid-column: span 4;
          }
          .span-2 {
            grid-column: span 8;
            width: 728px;
          }
        }
      `,
    },
  });
}

export const setup = () => {
  allowRead();
  if (featureFlags.ads === true) setupInContentAds();
  eventBus.on("adsStatusChange", (enable) => {
    if (enable) {
      setupInContentAds();
    } else {
      setFeatureFlag("adsInContentBanner", false);
    }
  });
};

export const teardown = () => {
  originals.restore();
};
